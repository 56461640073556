// src/styles/components/_footer.scss
.footer {
    padding: 1rem;
    background-color: #333;
    color: #fff;
    text-align: center;

a {
    color: #ff7f50;
    margin: 0 0.5rem;
}
}
