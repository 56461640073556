// src/styles/components/_product-grid.scss
.product-grid {
    padding: 2rem;
    text-align: center;

.grid {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
}
}
