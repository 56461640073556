// src/styles/components/_hero.scss
.hero {
    background: url('../../assets/hero.png') center center/cover no-repeat;
    height: 60vh;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

.hero-content {
    h1 {
    font-size: 2.5rem;
    }
    p {
    font-size: 1.25rem;
    }
    .btn-primary {
    background-color: #ff7f50;
    border: none;
    }
}
}
