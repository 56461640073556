// src/pages/Home.scss

.home {
    .featured-products {
    padding: 2rem 1rem;
    text-align: center;

    h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
    }
}

.about-section {
    padding: 2rem 1rem;
    background-color: #f9f9f9;
}
}
