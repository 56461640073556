// src/styles/components/_navbar.scss

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #333;
    color: #fff;
    position: relative;
    z-index: 100;

.navbar-logo {
    font-size: 1.75rem;
    font-weight: bold;
    color: #ff7f50;

    a {
    text-decoration: none;
    color: inherit;
    }
}

.menu-icon {
    display: none;
    cursor: pointer;

    .hamburger {
    width: 25px;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s ease;
    position: relative;

    &::before,
    &::after {
        content: '';
        width: 25px;
        height: 3px;
        background-color: #fff;
        position: absolute;
        transition: all 0.3s ease;
    }

    &::before {
        transform: translateY(-8px);
    }

    &::after {
        transform: translateY(8px);
    }

    &.open {
        background-color: transparent;

        &::before {
        transform: rotate(45deg);
        top: 0;
        }

        &::after {
        transform: rotate(-45deg);
        top: 0;
        }
    }
    }
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 2rem;

    li {
    a {
        text-decoration: none;
        color: #fff;
        font-size: 1.1rem;
        position: relative;
        padding: 0.5rem 0;

        &:hover {
        color: #ff7f50;
        }

        &::after {
        content: '';
        width: 0;
        height: 2px;
        background: #ff7f50;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: width 0.3s ease;
        }

        &:hover::after {
        width: 100%;
        }
    }
    }
}
}

/* Responsive Styles */
@media (max-width: 768px) {
.menu-icon {
    display: block;
}

.navbar-links {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;

    &.show {
    max-height: 300px; /* Adjust as needed */
    }

    li {
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    }
}
}
